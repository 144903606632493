import { createApp } from 'vue';
import VueAppleLogin from 'vue-apple-login';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';
import * as signalR from '@microsoft/signalr';
import excel from 'vue-excel-export';
import uploader from 'vue-simple-uploader';
import 'vue-simple-uploader/dist/style.css';
import App from './App.vue';
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const connection = new signalR.HubConnectionBuilder().withUrl('/hub').build();

connection.start().catch((err) => console.log(err));

store.dispatch('init').then(() => {
  const app = createApp(App).use(store).use(router).use(LoadingPlugin);

  Sentry.init({
    app,
    dsn: 'https://d3dde240464d47049e40665d08f8377b@o4504485054906368.ingest.sentry.io/4504576234815488',
    integrations: [],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  app.use(uploader);

    app.use(VueAppleLogin, {
        clientId: 'com.lightfield.industries.capture.stage',
        //clientId: 'com.lightfield.industries.capture',
        scope: 'name email',
        redirectURI: 'https://staging-clients.plinth.it',
        state: '1',
        usePopup: true,
    });



  app.config.globalProperties.$filters = {
    dateFormat(date: Date) {
      return `${date.toLocaleDateString('en-US')}, ${date.toLocaleTimeString(
        'en-US'
      )}`;
    },
  };
  app.use(excel);
  app.provide('connection', connection);
  app.mount('#app');
});
